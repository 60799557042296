import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { fetchReport } from "../api"
import { Table } from "../components/Table"
import ReportContainer from "../components/ReportContainer"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import ExternalLink from "../components/ExternalLink"
import { CATEGORY_COPY } from "../copy"
import { SENTIMENT_COPY } from "../copy"
import { Tabs } from "../components/Tabs"
import EntitiesScreened from "../components/EntitiesScreened"
import { formatTime } from "../utils"

const filterOther = (array: any) => {
  return array.filter((element: any) => element.type !== 'other')
}

const ReportDetails = () => {
  const navigate = useNavigate()
  const [report, setReport] = useState<any | null>(null)
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return
    }

    fetchReport(navigate, id).then(report => {
      setReport(report)
    })
  }, [id])

  const TABLE_CONFIG = [{
    header: 'Title',
    classNames: "",
    customRender: (row: any) => {
      return (
        <button
          type="button"
          className="px-6 flex justify-start text-start text-[#92111E] underline underline-offset-2"
          onClick={() => { navigate(`/reports/${id}/news_source/${row.id}`) }}
        >
          <div>
            {row.title}
          </div>
        </button>
      )
    }
  },
  {
    header: 'Author',
    classNames: "",
    key: 'author'
  },
  {
    header: 'Published Date',
    classNames: "",
    customRender: (row: any) => {
      return (
        <>
          {formatTime(row.publication_date)}
        </>
      )
    }
  },
  {
    header: 'Categories',
    classNames: "",
    customRender: (row: any) => {
      return (
        <div>
          {row.categories.map((category: any) => {
            return (
              <div key={category.type}>
                {
                  /* @ts-ignore */ 
                  CATEGORY_COPY[category.type]
                }
              </div>
            )
          })}
        </div>
      )
    }
  },
  {
    header: 'Sentiment',
    classNames: "",
    customRender: (row: any) => {
      return (
        <div>
          {
            /* @ts-ignore */ 
            SENTIMENT_COPY[row.sentiment.type]
          }
        </div>
      )
        
    }
  },
  {
    header: 'Mentions',
    classNames: "",
    customRender: (row: any) => {
      return (
        <div>
          {
            row.mentions.map((mention: any) => {
              return (
                <div key={mention.mentioned_id}>
                  {mention.metadata.name}
                </div>
              )
            })
          }
        </div>
      )
    }
  },
  {
    header: 'Link',
    classNames: "",
    minWidthPx: '50px',
    customRender: (row: any) => {
      return (
        <div className="flex justify-center items-start">
          <ExternalLink url={row.url} classNames="h-full">
            <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-[#92111E]"/>
          </ExternalLink>
        </div>
      )
    }
  }]

  if (!report) {
    return <></>
  }

  const sortedNewsSources = report.news_sources.sort((newsSourceA: any, newsSourceB: any) => {
    if (newsSourceA.sentiment.type === newsSourceB.sentiment.type) {
      return filterOther(newsSourceB.categories).length - filterOther(newsSourceA.categories).length
    } else if (newsSourceA.sentiment.type === 'negative') {
      return -1
    } else if (newsSourceB.sentiment.type === 'negative') {
      return 1
    } else if (newsSourceA.sentiment.type === 'neutral') {
      return -1
    } else if (newsSourceB.sentiment.type === 'neutral') {
      return 1
    }

    return -1
  })

  const TABS = [{
    title: 'News Sources',
    tabContent: (
      <Table
        columnConfigs={TABLE_CONFIG}
        rows={sortedNewsSources}
      />
    )},
    {
      title: 'Entities Screened',
      tabContent: (
        <div className="px-10 flex flex-col divide-y bg-white border text=">
          <EntitiesScreened title={'Business names screened'} entities={report.business.names} />
          {report.business.people.length > 0 && <EntitiesScreened title={'People screened'} entities={report.business.people} />}
        </div>
    )}
  ]

  return (
    <ReportContainer report={report}>
      <>
        {report.news_sources && (
          <div className="">
            <Tabs tabs={TABS} />
          </div>
        )}
      </>
    </ReportContainer>
  )
}

export default ReportDetails