import { ReactElement } from "react"
import { CATEGORY_COPY } from "../copy"
import { SENTIMENT_COPY } from "../copy"
import { formatPercentage } from "../utils"
import SmallHeader from "./SmallHeader"

type NewsSourceHighLevelProps = {
  newsSource: any
}

const Section = ({ children }: { children: ReactElement}) =>  {
  return (
    <div className="flex flex-col border-b py-6">
      {children}
    </div>
  )
}

const NewsSourceHighLevel = ({ newsSource }: NewsSourceHighLevelProps) => {
  return (
    <div className="border px-6 items-start min-w-[300px] rounded-md">
      <Section>
        <>
          <SmallHeader>Sentiment</SmallHeader>
          <div className="flex flex-row justify-between">
            <div>
              {
                /* @ts-ignore */
                SENTIMENT_COPY[newsSource.sentiment.type]
              }
            </div>
            <div>
              {formatPercentage(newsSource.sentiment.score)}
            </div>
          </div>
        </>
      </Section>
      <Section>
        <>
          <SmallHeader>Categories</SmallHeader>
            <>
            {
              newsSource.categories.map((category: any) => {
                return (
                  <div className="flex flex-row justify-between">
                    <div key={category.type}>
                      {
                        /* @ts-ignore */
                        CATEGORY_COPY[category.type]
                      }
                    </div>
                    <div>
                      {formatPercentage(category.score)}
                    </div>
                  </div>
                )
              })
            }
          </>
        </>
      </Section>
      <Section>
        <>
          <SmallHeader>Mentions</SmallHeader>
          <>
            <div className="flex flex-col">
              {newsSource.mentions.map((mention: any) => {
                return (
                  <div key={mention.mentioned_id}>
                    {mention.metadata.name}
                  </div>
                )
              })}
            </div>
          </>
        </>
      </Section>
    </div>
  )
}

export default NewsSourceHighLevel