import { ReactElement, useState } from "react"

type TabConfig = {
  title: string
  tabContent: ReactElement
}

type TabProps = {
  tabs: TabConfig[]
}

export const Tabs = ({ tabs }: TabProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <>
      <div className="flex flex-row bg-white">
        {tabs.map((tab, index) => {
          const color = index === activeTabIndex ? "bg-gradient-to-t from-[#FBDADE] to-white border-b-2 border-b-[#92111E] text-[#92111E]" : "bg-white" 

          return (
            <div className={`cursor-pointer border-l py-1 px-10 font-semibold ${color}`} onClick={() => setActiveTabIndex(index)}>
              {tab.title}
            </div>
          )
        })}
      </div>
      {tabs[activeTabIndex].tabContent}
    </>
  )
}