import { ReactElement } from "react"
import { Navigate } from "react-router-dom"
import { isLoggedIn } from "../auth/utils"

type PrivateRouteProps = {
  children: ReactElement
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  if (!isLoggedIn()) {
    return <Navigate to="/signin" replace />;
  }

  return <>
    {children}
  </>
}

export default PrivateRoute