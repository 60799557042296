import { ReactElement } from "react"

type SmallHeaderProps = {
  children?: ReactElement | string
}

const SmallHeader = ({children}: SmallHeaderProps) => {
  return (
    <div className="text-lg font-bold pb-3 text-[#86859B]">
      {children}
    </div>
  )
}

export default SmallHeader