import Pill from "./Pill"

const RISK_LEVELS = {
  'low': {
    textColor: 'text-white',
    color: 'bg-[#19bcab]'
  },
  'medium': {
    textColor: 'text-white',
    color: 'bg-[#fb823d]'
  },
  'high': {
    textColor: 'text-white',
    color: 'bg-[#d11d2f]'
  }
}

type RiskLevelProps = {
  riskLevel: 'low' | 'medium' | 'high'
}

const RiskLevelPill = ({ riskLevel }: RiskLevelProps) => {
  const config = RISK_LEVELS[riskLevel]

  return (
    <Pill text={riskLevel} textColor={config.textColor} color={config.color} />
  )
}

export default RiskLevelPill