import { useNavigate } from "react-router-dom"
import Button from "../components/Button"
import PageContainer from "../components/PageContainer"
import RiskLevelPill from "../components/RiskLevelPill"
import { Table } from "../components/Table"
import { formatTime } from "../utils"
import { useEffect, useState } from "react"
import { fetchReports } from "../api"

// header: string
//   key?: string
//   classNames: string
//   customRender?: ((row: any) => string | undefined) | ((row: any) => ReactElement)
//   minWidthPx?: string

const COLUMN_CONFIG = [
  {
    header: 'Name',
    classNames: "",
    customRender: (row: any) => <div>{row.business.names[0].name}</div>
  },
  {
    header: 'Created at',
    classNames: "",
    customRender: (row: any) => (
      <div>
        {formatTime(row.created_at)}
      </div>
    )
  },
  {
    header: 'Status',
    classNames: "",
    key: 'status'
  },
  {
    header: 'Risk level',
    classNames: "",
    customRender: (row: any) => {
      return (
        <div className="flex justify-center">
          <RiskLevelPill riskLevel={row.risk_summary.risk_level} />
        </div>
      )
    }
  }
]

const Home = () => {
  const navigate = useNavigate()
  const [reports, setReports] = useState([])

  useEffect(() => {
    fetchReports(navigate).then((reports) => {
      setReports(reports)
    })
  }, [])

  return (
    <PageContainer>
      <div className="pt-10">
        <div className="flex flex-row justify-end pb-6">
          <Button onClick={() => navigate('/create-report')}>Create report</Button>
        </div>
        <Table
          columnConfigs={COLUMN_CONFIG}
          rows={reports}
          onRowClick={(row) => { navigate(`/reports/${row.id}`) }}
          tableClassNames="rounded-md"
        />
      </div>
    </PageContainer>
  )
}

export default Home