
import { ReactElement } from "react"

type ColumnConfig = {
  header: string
  key?: string
  classNames: string
  customRender?: ((row: any) => string | undefined) | ((row: any) => ReactElement)
  minWidthPx?: string
}

type TableDataProps = {
  row: any
  keyString?: string
  classNames: string
  customRender?: ((row: any) => string | undefined) | ((row: any) => ReactElement)
  minWidthPx?: string
}

type TableHeaderProps = {
  header: string
}

type TableProps = {
  columnConfigs: Array<ColumnConfig>
  rows: Array<any>
  onRowClick?: (row: any) => void
  tableClassNames?: string
}

const TableHeader = ({ header }: TableHeaderProps) => {
  return <th className="p-3 font-medium text-xs text-[#86859B]">{header}</th>
}

const TableData = ({ row, keyString, classNames, customRender, minWidthPx }: TableDataProps) => {
  const width = minWidthPx ? minWidthPx: 'min-w-[150px]'

  return (
    <td className={`px-3 py-6 text-sm text-center ${width} ${classNames ? classNames : ''}`}>
      {keyString ? row[keyString]: customRender ? customRender(row) : <></>}
    </td>
  )
}

export const Table = ({ columnConfigs, rows, onRowClick, tableClassNames }: TableProps) => {
  const rowClassNames = onRowClick ? "cursor-pointer" : ""

  return (
    <div>
      <div className={`overflow-x-auto border-t border-r border-l ${tableClassNames}`} >
        <table className="w-full bg-white">
          <thead className="bg-[#FBFAFE]">
            <tr>
              {columnConfigs.map((columnConfig, index) => <TableHeader key={index} header={columnConfig.header} />)}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              return (
                <tr
                  key={index}
                  className={`border-solid border-t border-b ${rowClassNames}`}
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {columnConfigs.map((columnConfig, colIndex) => (
                    <TableData
                      key={`${index}-${colIndex}`}
                      row={row}
                      classNames={columnConfig.classNames}
                      keyString={columnConfig.key}
                      customRender={columnConfig.customRender}
                      minWidthPx={columnConfig.minWidthPx}
                    />)
                  )}
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}