
const FormattedText = ({ text }: { text?: string}) => {
  if (!text) {
    return <></>
  }

  const splitSummary = text.split('\n')

  return (
    <div>
      {splitSummary.map((section, index) => {
        const sanitizedSection = section.replaceAll("#", "")
        const matchedBoldRegex = sanitizedSection.matchAll(/\*\*([^\*]*)\*\*/g)

        const boldSections = new Set(Array.from(matchedBoldRegex).map((matchedData: any) => {
          return matchedData[1]
        }))
        const splitSection = sanitizedSection.split("**")

        return (
          <div key={index}>
            {splitSection.map((snippet: any) => {
              if (boldSections.has(snippet)) {
                return <b className="font-bold">{snippet}</b>
              }

              return snippet
            })}
            {index !== splitSummary.length - 1 && (<><br /><br /></>)}
          </div>
        )
      })}
    </div>
  )
}

export default FormattedText