import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { SignIn } from './pages/SignIn';
import rootReducer from './rootReducer';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import Home from './pages/Home';
import CreateReport from './pages/CreateReport';
import ReportDetails from './pages/ReportDetails';
import ReportNewsSourceDetails from './pages/ReportNewsSourceDetails';

const store = configureStore({
  reducer: rootReducer
})

const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<PrivateRoute><Home></Home></PrivateRoute>} />
        <Route path="/create-report" element={<PrivateRoute><CreateReport></CreateReport></PrivateRoute>} />
        <Route path='/reports/:id' element={<PrivateRoute><ReportDetails></ReportDetails></PrivateRoute>} />
        <Route
          path='/reports/:id/news_source/:news_source_id'
          element={<PrivateRoute><ReportNewsSourceDetails></ReportNewsSourceDetails></PrivateRoute>}
        />
        <Route path="/signin" element={<SignIn />} />
      </>
    )
  )

function App() {
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router}/>
      </Provider>
    </>
  )
}

export default App
