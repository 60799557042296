type PillProps = {
  text: string
  color: string
  textColor: string
}

const Pill = ({ text, color, textColor }: PillProps) => {
  return (
    <div className={`shadow-inner ${color} ${textColor} rounded-full py-1 px-6 w-fit`}>
      {text}
    </div>
  )
}

export default Pill