import { Navigate } from 'react-router-dom';
import { isLoggedIn } from '../auth/utils'
import GoogleSignInButton from '../components/GoogleSignInButton'

export const SignIn = () => {

  if (isLoggedIn()) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex flex-row align-center justify-center w-screen h-screen bg-[#F8F4F1]">
      <div className="flex flex-col align-center justify-center">
        <div className="flex flex-col bg-white border shadow gap-16 p-16 rounded-xl">
          <div className="flex flex-row justify-center">
            <h3 className="text-2xl">Welcome to Riveter AI</h3>
          </div>
          <div className="flex flex-row justify-center">
            <h1 className="text-5xl font-bold">Approve more good customers, faster</h1>
          </div>
          <div className="flex flex-row justify-center">
            <GoogleSignInButton/>
          </div>
        </div>
      </div>
    </div>
  )
}
