import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import PageContainer from "../components/PageContainer"
import Button from "../components/Button"
import { TrashIcon } from "@heroicons/react/24/outline"
import { createReport } from "../api"
import { useNavigate } from "react-router-dom"
import { useState } from "react"

type Inputs = {
  name: string
  people: string[]
}
const CreateReport = () => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const {
    register,
    handleSubmit,
    control,
  } = useForm<Inputs>()
  const { fields, append, remove } = useFieldArray({
    control,
    /* @ts-ignore */
    name: "people"
  });
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const peopleRequest = data.people.map((person) => { return { name: person } })

    setSubmitting(true)
    createReport(navigate, {
      business: {
        names: [{
          name: data.name
        }],
        people: peopleRequest
      }
    }).then(() => {
      setSubmitting(false)
      navigate("/")
    }).catch(() => {
      setSubmitting(false)
    })
  }

  return (
    <PageContainer>
      <div className="flex justify-center">
        <div className="max-w-xl">
          <div className="w-full">
            <div className="justify-start">
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6 w-[400px]">
                <h1 className="text-2xl font-bold mt-20 color-[#86859B]">Create News Report</h1>
                <div className="flex flex-col gap-1">
                  <label className="text-[#86859B] text-sm">Business Name</label>      
                  <input {...register('name')} className="border border-slate-300 rounded-lg px-2 py-1"/>
                </div>

                <div className="flex flex-col gap-1">
                  {fields.length > 0 && <label className="text-[#86859B] text-sm">People</label>}
                  <div className="flex flex-col gap-2">
                    {fields.map((field, index) => (
                      <div key={field.id} className="flex flex-row">
                        <input
                          {...register(`people.${index}`)}
                          className="flex-1 flex-grow border border-slate-300 rounded-lg px-2 py-1"
                        />
                        <button type="button" onClick={() => remove(index)} className="flex py-1 pl-3 items-center">
                          <TrashIcon className="h-5 w-5 stroke-slate-500" />
                        </button>
                      </div>
                   ))}
                    <button type="button" onClick={() => append("")} className="self-start p-1 text-[#92111E]">
                      Add person
                    </button>
                  </div>
                </div>

                <Button type="submit" className="self-end" disabled={submitting} onClick={handleSubmit(onSubmit)}>
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default CreateReport