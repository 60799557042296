import { useNavigate, useParams } from "react-router-dom"
import ReportContainer from "../components/ReportContainer"
import { useEffect, useState } from "react"
import { fetchNewsSource, fetchReport } from "../api"
import { ArrowTopRightOnSquareIcon, ChevronLeftIcon } from "@heroicons/react/24/outline"
import NewsSourceHighLevel from "../components/NewsSourceHighLevel"
import ExternalLink from "../components/ExternalLink"
import { formatDate } from "../utils"
import FormattedText from "../components/FormattedText"

const ReportNewsSourceDetails = () => {
  const navigate = useNavigate()
  const { id, news_source_id } = useParams()
  const [report, setReport] = useState<any | null>(null)
  const [newsSource, setNewsSource] = useState<any | null>(null)

  useEffect(() => {
    if (!id) {
      return
    }

    fetchReport(navigate, id).then(report => {
      setReport(report)
    })
  }, [id])

  useEffect(() => {
    if (!news_source_id) {
      return
    }

    fetchNewsSource(navigate, news_source_id).then(news_source => {
      setNewsSource(news_source)
    })
  }, [news_source_id])



  return (
    <ReportContainer report={report}>
      <>
        {newsSource && (
          <div className="border flex flex-row items-start justify-between gap-10 p-10 bg-white">
            <div className="flex flex-row items-start gap-10">
              <button onClick={() => navigate(`/reports/${id}`)}>
                <ChevronLeftIcon className='w-8 h-8 stroke-slate-500' />
              </button>
              <div>
                <div className="font-bold text-2xl pb-1 flex flex-row items-center gap-3">
                  {newsSource.title}
                  <ExternalLink url={newsSource.url}>
                    <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-[#92111E]"/>
                  </ExternalLink>
                </div>
                <div className="flex flex-row text-center text-sm">
                  {newsSource.author && (
                    <>
                      by
                      <div className="text-sm font-bold text-[#86859B] pb-6 pl-1">
                        {newsSource.author}
                      </div>
                      ,
                    </>
                  )}
                  {newsSource.publication_date && (
                    <div className="text-sm pb-6 pl-1">
                      {formatDate(newsSource.publication_date)}
                    </div>
                  )}
                </div>
                <FormattedText text={newsSource.text} />
              </div>
            </div>
            <NewsSourceHighLevel newsSource={newsSource} />
          </div>
        )}
      </>
    </ReportContainer>
  )
}

export default ReportNewsSourceDetails