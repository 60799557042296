import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import { RIVETER_COOKIE } from '../api';

const GoogleSignInButton = () => {
  const navigate = useNavigate();

  const responseMessage = (response: CredentialResponse) => {
    console.log(response)
    axios({
      method: "POST",
      url: `${import.meta.env.VITE_API_URL}/google-sign-in`,
      data: response
    }).then(response => {
      Cookies.set(RIVETER_COOKIE, response.data.data.token, { expires: 7 })
      navigate('/')
    })
    .catch(error => console.log(error))
  };
  
  const errorMessage = () => {
    console.log("error");
  };

  return (
    <GoogleLogin onSuccess={responseMessage} onError={errorMessage}/>
  )
}

export default GoogleSignInButton