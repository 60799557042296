import axios from "axios"
import Cookies from 'js-cookie'

export const RIVETER_COOKIE = 'X-Riveter-Cookie'

export const downloadResponseFile = (response: any, fileName: string) => {
  const href = URL.createObjectURL(response.data)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export const fetchReports = async (navigate: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/reports`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)
      console.log('navigating...')
      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const createReport = async (navigate: any, body: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.post(`${import.meta.env.VITE_API_URL}/reports`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)
      console.log('navigating...')
      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
} 

export const fetchReport = async (navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/reports/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchNewsSource = async (navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/news_source/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}
