import RiskLevelPill from "./RiskLevelPill"
import SmallHeader from "./SmallHeader"

type EntitiesScreenedProps = {
  title: string
  entities: any[]
}

type EntityRiskProps = {
  entity: any
}

const EntityRisk = ({ entity }: EntityRiskProps) => {
  return (
    <div className="flex flex-row w-full justify-between items-center">
      <div>{entity.name}</div>
      <RiskLevelPill riskLevel={entity.risk_summary.risk_level} />
    </div>
  )
}

const EntitiesScreened = ({ title, entities }: EntitiesScreenedProps) => {
  const columnOne = entities.slice(0, entities.length / 2 + (entities.length % 2 === 0 ? 0 : 1))
  const columnTwo = entities.slice(entities.length / 2 + (entities.length % 2 === 0 ? 0 : 1), entities.length)

  return (
    <div className="py-10">
      <SmallHeader>{title}</SmallHeader>
      <div className="flex flex-row w-full justify-between gap-10">
        <div className="flex flex-col gap-2 w-1/2 ">
          {columnOne.map((entity: any) => {
            return (
              <EntityRisk key={entity.id} entity={entity} />
            )
          })}
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          {columnTwo.map((entity: any) => {
            return (
              <EntityRisk key={entity.id} entity={entity} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default EntitiesScreened