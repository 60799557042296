export const CATEGORY_COPY = {
  terrorism: 'Terrorism',
  bankruptcy: 'Bankruptcy',
  bribery_corruption: 'Bribery & Corruption',
  criminal_activity_individual: 'Criminal Activity (Individual)',
  criminal_activity_business: 'Criminal Activity (Business)',
  fraud: 'Fraud',
  money_laundering: 'Money Laundering',
  tax_evasion: 'Tax Evasion',
  closing: 'Closing',
  compliance: 'Compliance',
  cybercrime: 'Cybercrime',
  data_breach: 'Data breach',
  death: 'Death',
  insider_trading: 'Insider trading',
  lawsuits: 'Lawsuits',
  other: 'Other'
}

export const SENTIMENT_COPY = {
  positive: 'Positive',
  neutral: 'Neutral',
  negative: 'Negative'
}