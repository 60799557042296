import { ReactElement } from "react"
import RiskLevelPill from "./RiskLevelPill"
import PageContainer from "./PageContainer"

type ReportContainerProps = {
  report?: any
  children: ReactElement
}

const ReportContainer = ({report, children }: ReportContainerProps) => {
  if (!report) {
    return <></>
  }

  return (
    <PageContainer>
      <div className="py-10">
        <div className="flex flex-row gap-6 items-center py-10 px-10 border-t border-r border-l rounded-t-md bg-white">
          <div className="text-4xl color-slate-900 font-bold">{report.business.names[0].name}</div>
          <RiskLevelPill riskLevel={report.risk_summary.risk_level} />
        </div>
        {children}
      </div>
    </PageContainer>
  )
}

export default ReportContainer