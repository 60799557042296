import Cookies from "js-cookie"
import { ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import ExtendedLogo from "./../assets/extended-logo.svg?react" 
import { RIVETER_COOKIE } from "../api"

const Header = () => {
  const navigate = useNavigate()

  const signOut = () => {
    Cookies.remove(RIVETER_COOKIE)

    navigate('/signin')
  }

  return (
    <div className="flex flex-row border-b border-b-slate-200 justify-center shadow h-[75px]">
      <div className="flex flex-row justify-between items-center mx-36 w-full">
        <button className="cursor-pointer" onClick={() => { navigate('/') }}>
          <ExtendedLogo className="h-10 w-full"/>
        </button>
        <button onClick={signOut}>
          <div className="self-center">Sign out</div>
        </button>
      </div>
    </div>
  )
}

type PageContainerProps = {
  children?: ReactElement
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div>
      <Header />
      <div className="px-36 bg-[#F8F4F1] h-screen">
        {children}
      </div>
    </div>
  )
}

export default PageContainer